import * as React from "react";
import styled from "styled-components";
import Seo from "../components/Seo";
import "../components/styles/main.css";
import { useEffect } from "react";
import { isIOS, isMobile } from "../helpers/platform";
import {
  APP_STORE_URL,
  DISCORD_INVITE_URL,
  GOOGLE_PLAY_URL,
} from "../constants";

const Page = styled.div`
  min-height: 100vh;
  background: #000212;
  color: white;
`;

const Container = styled.div`
  width: 100%;
  max-width: 730px;
  margin: 0 auto;
  text-align: center;
`;

const Heading = styled.h1`
  font-size: 46px;
  font-weight: 700;
  margin-bottom: 55px;
  font-family: EuclidFlex-Semibold-WebTrial;
`;

const Text = styled.p`
  font-size: 22px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Footer = styled(Row)`
  justify-content: center;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 24px;
`;

const Main = styled.main`
  padding-top: 24vh;
`;

const List = styled.ul`
  display: flex;
  align-items: center;
  @media screen and (max-width: 545px) {
    flex-flow: column nowrap;
  }
`;

const ListItem = styled.li`
  margin-right: 22px;
  &:last-of-type {
    margin-right: 0;
  }

  @media screen and (max-width: 545px) {
    margin-right: 0px;
    margin-bottom: 18px;
  }
`;

const Link = styled.a`
  color: white;
`;

const PlatformIconWrapper = styled.a`
  width: 144px;
  height: 40px;
  margin-right: 10px;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    cursor: pointer;
  }
`;

const DiscordIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 127.14 96.36"
      width="24px"
      height="20px"
      style={{
        marginRight: "4px",
      }}
    >
      <path
        fill="rgb(190, 192, 200)"
        d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"
      />
    </svg>
  );
};
const AppStoreIcon = () => (
  <svg
    id="livetype"
    xmlns="http://www.w3.org/2000/svg"
    width="119.66407"
    height="40"
    viewBox="0 0 119.66407 40"
  >
    <title>Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917</title>
    <g>
      <g>
        <g>
          <path d="M110.13477,0H9.53468c-.3667,0-.729,0-1.09473.002-.30615.002-.60986.00781-.91895.0127A13.21476,13.21476,0,0,0,5.5171.19141a6.66509,6.66509,0,0,0-1.90088.627A6.43779,6.43779,0,0,0,1.99757,1.99707,6.25844,6.25844,0,0,0,.81935,3.61816a6.60119,6.60119,0,0,0-.625,1.90332,12.993,12.993,0,0,0-.1792,2.002C.00587,7.83008.00489,8.1377,0,8.44434V31.5586c.00489.3105.00587.6113.01515.9219a12.99232,12.99232,0,0,0,.1792,2.0019,6.58756,6.58756,0,0,0,.625,1.9043A6.20778,6.20778,0,0,0,1.99757,38.001a6.27445,6.27445,0,0,0,1.61865,1.1787,6.70082,6.70082,0,0,0,1.90088.6308,13.45514,13.45514,0,0,0,2.0039.1768c.30909.0068.6128.0107.91895.0107C8.80567,40,9.168,40,9.53468,40H110.13477c.3594,0,.7246,0,1.084-.002.3047,0,.6172-.0039.9219-.0107a13.279,13.279,0,0,0,2-.1768,6.80432,6.80432,0,0,0,1.9082-.6308,6.27742,6.27742,0,0,0,1.6172-1.1787,6.39482,6.39482,0,0,0,1.1816-1.6143,6.60413,6.60413,0,0,0,.6191-1.9043,13.50643,13.50643,0,0,0,.1856-2.0019c.0039-.3106.0039-.6114.0039-.9219.0078-.3633.0078-.7246.0078-1.0938V9.53613c0-.36621,0-.72949-.0078-1.09179,0-.30664,0-.61426-.0039-.9209a13.5071,13.5071,0,0,0-.1856-2.002,6.6177,6.6177,0,0,0-.6191-1.90332,6.46619,6.46619,0,0,0-2.7988-2.7998,6.76754,6.76754,0,0,0-1.9082-.627,13.04394,13.04394,0,0,0-2-.17676c-.3047-.00488-.6172-.01074-.9219-.01269-.3594-.002-.7246-.002-1.084-.002Z" />
          <path
            d="M8.44483,39.125c-.30468,0-.602-.0039-.90429-.0107a12.68714,12.68714,0,0,1-1.86914-.1631,5.88381,5.88381,0,0,1-1.65674-.5479,5.40573,5.40573,0,0,1-1.397-1.0166,5.32082,5.32082,0,0,1-1.02051-1.3965,5.72186,5.72186,0,0,1-.543-1.6572,12.41351,12.41351,0,0,1-.1665-1.875c-.00634-.2109-.01464-.9131-.01464-.9131V8.44434S.88185,7.75293.8877,7.5498a12.37039,12.37039,0,0,1,.16553-1.87207,5.7555,5.7555,0,0,1,.54346-1.6621A5.37349,5.37349,0,0,1,2.61183,2.61768,5.56543,5.56543,0,0,1,4.01417,1.59521a5.82309,5.82309,0,0,1,1.65332-.54394A12.58589,12.58589,0,0,1,7.543.88721L8.44532.875H111.21387l.9131.0127a12.38493,12.38493,0,0,1,1.8584.16259,5.93833,5.93833,0,0,1,1.6709.54785,5.59374,5.59374,0,0,1,2.415,2.41993,5.76267,5.76267,0,0,1,.5352,1.64892,12.995,12.995,0,0,1,.1738,1.88721c.0029.2832.0029.5874.0029.89014.0079.375.0079.73193.0079,1.09179V30.4648c0,.3633,0,.7178-.0079,1.0752,0,.3252,0,.6231-.0039.9297a12.73126,12.73126,0,0,1-.1709,1.8535,5.739,5.739,0,0,1-.54,1.67,5.48029,5.48029,0,0,1-1.0156,1.3857,5.4129,5.4129,0,0,1-1.3994,1.0225,5.86168,5.86168,0,0,1-1.668.5498,12.54218,12.54218,0,0,1-1.8692.1631c-.2929.0068-.5996.0107-.8974.0107l-1.084.002Z"
            style={{
              fill: "#fff",
            }}
          />
        </g>
        <g id="_Group_" data-name="&lt;Group&gt;">
          <g id="_Group_2" data-name="&lt;Group&gt;">
            <g id="_Group_3" data-name="&lt;Group&gt;">
              <path
                id="_Path_"
                data-name="&lt;Path&gt;"
                d="M24.99671,19.88935a5.14625,5.14625,0,0,1,2.45058-4.31771,5.26776,5.26776,0,0,0-4.15039-2.24376c-1.74624-.1833-3.43913,1.04492-4.329,1.04492-.90707,0-2.27713-1.02672-3.75247-.99637a5.52735,5.52735,0,0,0-4.65137,2.8367c-2.01111,3.482-.511,8.59939,1.41551,11.414.96388,1.37823,2.09037,2.91774,3.56438,2.86315,1.4424-.05983,1.98111-.91977,3.7222-.91977,1.72494,0,2.23035.91977,3.73427.88506,1.54777-.02512,2.52292-1.38435,3.453-2.77563a11.39931,11.39931,0,0,0,1.579-3.21589A4.97284,4.97284,0,0,1,24.99671,19.88935Z"
              />
              <path
                id="_Path_2"
                data-name="&lt;Path&gt;"
                d="M22.15611,11.47681a5.06687,5.06687,0,0,0,1.159-3.62989,5.15524,5.15524,0,0,0-3.33555,1.72582,4.82131,4.82131,0,0,0-1.18934,3.4955A4.26259,4.26259,0,0,0,22.15611,11.47681Z"
              />
            </g>
          </g>
          <g>
            <path d="M42.30178,27.13965h-4.7334l-1.13672,3.35645H34.42678l4.4834-12.418h2.083l4.4834,12.418H43.43752Zm-4.24316-1.54883h3.752L39.961,20.14355H39.9092Z" />
            <path d="M55.1592,25.96973c0,2.81348-1.50586,4.62109-3.77832,4.62109a3.0693,3.0693,0,0,1-2.84863-1.584h-.043v4.48438h-1.8584V21.44238h1.79883v1.50586h.03418a3.21162,3.21162,0,0,1,2.88281-1.60059C53.64455,21.34766,55.1592,23.16406,55.1592,25.96973Zm-1.91016,0c0-1.833-.94727-3.03809-2.39258-3.03809-1.41992,0-2.375,1.23047-2.375,3.03809,0,1.82422.95508,3.0459,2.375,3.0459C52.30178,29.01563,53.249,27.81934,53.249,25.96973Z" />
            <path d="M65.12453,25.96973c0,2.81348-1.50635,4.62109-3.77881,4.62109a3.0693,3.0693,0,0,1-2.84863-1.584h-.043v4.48438h-1.8584V21.44238h1.79883v1.50586h.03418a3.21162,3.21162,0,0,1,2.88281-1.60059C63.6094,21.34766,65.12453,23.16406,65.12453,25.96973Zm-1.91064,0c0-1.833-.94727-3.03809-2.39258-3.03809-1.41992,0-2.375,1.23047-2.375,3.03809,0,1.82422.95508,3.0459,2.375,3.0459C62.26662,29.01563,63.21389,27.81934,63.21389,25.96973Z" />
            <path d="M71.70949,27.03613c.1377,1.23145,1.334,2.04,2.96875,2.04,1.56641,0,2.69336-.80859,2.69336-1.91895,0-.96387-.67969-1.541-2.28906-1.93652l-1.60937-.3877c-2.28027-.55078-3.33887-1.61719-3.33887-3.34766,0-2.14258,1.86719-3.61426,4.51758-3.61426,2.625,0,4.42383,1.47168,4.48438,3.61426h-1.876c-.1123-1.23926-1.13672-1.9873-2.63379-1.9873s-2.52148.75684-2.52148,1.8584c0,.87793.6543,1.39453,2.25488,1.79l1.36816.33594c2.54785.60254,3.60547,1.626,3.60547,3.44238,0,2.32324-1.84961,3.77832-4.793,3.77832-2.75391,0-4.61328-1.4209-4.7334-3.667Z" />
            <path d="M83.34621,19.2998v2.14258h1.72168v1.47168H83.34621v4.99121c0,.77539.34473,1.13672,1.10156,1.13672a5.80752,5.80752,0,0,0,.61133-.043v1.46289a5.10351,5.10351,0,0,1-1.03223.08594c-1.833,0-2.54785-.68848-2.54785-2.44434V22.91406H80.16262V21.44238H81.479V19.2998Z" />
            <path d="M86.064,25.96973c0-2.84863,1.67773-4.63867,4.29395-4.63867,2.625,0,4.29492,1.79,4.29492,4.63867,0,2.85645-1.66113,4.63867-4.29492,4.63867C87.72512,30.6084,86.064,28.82617,86.064,25.96973Zm6.69531,0c0-1.9541-.89551-3.10742-2.40137-3.10742s-2.40137,1.16211-2.40137,3.10742c0,1.96191.89551,3.10645,2.40137,3.10645S92.7593,27.93164,92.7593,25.96973Z" />
            <path d="M96.18508,21.44238h1.77246v1.541h.043a2.1594,2.1594,0,0,1,2.17773-1.63574,2.86616,2.86616,0,0,1,.63672.06934v1.73828a2.59794,2.59794,0,0,0-.835-.1123,1.87264,1.87264,0,0,0-1.93652,2.083v5.37012h-1.8584Z" />
            <path d="M109.38332,27.83691c-.25,1.64355-1.85059,2.77148-3.89844,2.77148-2.63379,0-4.26855-1.76465-4.26855-4.5957,0-2.83984,1.64355-4.68164,4.19043-4.68164,2.50488,0,4.08008,1.7207,4.08008,4.46582v.63672h-6.39453v.1123a2.358,2.358,0,0,0,2.43555,2.56445,2.04834,2.04834,0,0,0,2.09082-1.27344Zm-6.28223-2.70215h4.52637a2.1773,2.1773,0,0,0-2.2207-2.29785A2.292,2.292,0,0,0,103.10109,25.13477Z" />
          </g>
        </g>
      </g>
      <g id="_Group_4" data-name="&lt;Group&gt;">
        <g>
          <path d="M37.82619,8.731a2.63964,2.63964,0,0,1,2.80762,2.96484c0,1.90625-1.03027,3.002-2.80762,3.002H35.67092V8.731Zm-1.22852,5.123h1.125a1.87588,1.87588,0,0,0,1.96777-2.146,1.881,1.881,0,0,0-1.96777-2.13379h-1.125Z" />
          <path d="M41.68068,12.44434a2.13323,2.13323,0,1,1,4.24707,0,2.13358,2.13358,0,1,1-4.24707,0Zm3.333,0c0-.97607-.43848-1.54687-1.208-1.54687-.77246,0-1.207.5708-1.207,1.54688,0,.98389.43457,1.55029,1.207,1.55029C44.57522,13.99463,45.01369,13.42432,45.01369,12.44434Z" />
          <path d="M51.57326,14.69775h-.92187l-.93066-3.31641h-.07031l-.92676,3.31641h-.91309l-1.24121-4.50293h.90137l.80664,3.436h.06641l.92578-3.436h.85254l.92578,3.436h.07031l.80273-3.436h.88867Z" />
          <path d="M53.85354,10.19482H54.709v.71533h.06641a1.348,1.348,0,0,1,1.34375-.80225,1.46456,1.46456,0,0,1,1.55859,1.6748v2.915h-.88867V12.00586c0-.72363-.31445-1.0835-.97168-1.0835a1.03294,1.03294,0,0,0-1.0752,1.14111v2.63428h-.88867Z" />
          <path d="M59.09377,8.437h.88867v6.26074h-.88867Z" />
          <path d="M61.21779,12.44434a2.13346,2.13346,0,1,1,4.24756,0,2.1338,2.1338,0,1,1-4.24756,0Zm3.333,0c0-.97607-.43848-1.54687-1.208-1.54687-.77246,0-1.207.5708-1.207,1.54688,0,.98389.43457,1.55029,1.207,1.55029C64.11232,13.99463,64.5508,13.42432,64.5508,12.44434Z" />
          <path d="M66.4009,13.42432c0-.81055.60352-1.27783,1.6748-1.34424l1.21973-.07031v-.38867c0-.47559-.31445-.74414-.92187-.74414-.49609,0-.83984.18213-.93848.50049h-.86035c.09082-.77344.81836-1.26953,1.83984-1.26953,1.12891,0,1.76563.562,1.76563,1.51318v3.07666h-.85547v-.63281h-.07031a1.515,1.515,0,0,1-1.35254.707A1.36026,1.36026,0,0,1,66.4009,13.42432Zm2.89453-.38477v-.37646l-1.09961.07031c-.62012.0415-.90137.25244-.90137.64941,0,.40527.35156.64111.835.64111A1.0615,1.0615,0,0,0,69.29543,13.03955Z" />
          <path d="M71.34816,12.44434c0-1.42285.73145-2.32422,1.86914-2.32422a1.484,1.484,0,0,1,1.38086.79h.06641V8.437h.88867v6.26074h-.85156v-.71143h-.07031a1.56284,1.56284,0,0,1-1.41406.78564C72.0718,14.772,71.34816,13.87061,71.34816,12.44434Zm.918,0c0,.95508.4502,1.52979,1.20313,1.52979.749,0,1.21191-.583,1.21191-1.52588,0-.93848-.46777-1.52979-1.21191-1.52979C72.72121,10.91846,72.26613,11.49707,72.26613,12.44434Z" />
          <path d="M79.23,12.44434a2.13323,2.13323,0,1,1,4.24707,0,2.13358,2.13358,0,1,1-4.24707,0Zm3.333,0c0-.97607-.43848-1.54687-1.208-1.54687-.77246,0-1.207.5708-1.207,1.54688,0,.98389.43457,1.55029,1.207,1.55029C82.12453,13.99463,82.563,13.42432,82.563,12.44434Z" />
          <path d="M84.66945,10.19482h.85547v.71533h.06641a1.348,1.348,0,0,1,1.34375-.80225,1.46456,1.46456,0,0,1,1.55859,1.6748v2.915H87.605V12.00586c0-.72363-.31445-1.0835-.97168-1.0835a1.03294,1.03294,0,0,0-1.0752,1.14111v2.63428h-.88867Z" />
          <path d="M93.51516,9.07373v1.1416h.97559v.74854h-.97559V13.2793c0,.47168.19434.67822.63672.67822a2.96657,2.96657,0,0,0,.33887-.02051v.74023a2.9155,2.9155,0,0,1-.4834.04541c-.98828,0-1.38184-.34766-1.38184-1.21582v-2.543h-.71484v-.74854h.71484V9.07373Z" />
          <path d="M95.70461,8.437h.88086v2.48145h.07031a1.3856,1.3856,0,0,1,1.373-.80664,1.48339,1.48339,0,0,1,1.55078,1.67871v2.90723H98.69v-2.688c0-.71924-.335-1.0835-.96289-1.0835a1.05194,1.05194,0,0,0-1.13379,1.1416v2.62988h-.88867Z" />
          <path d="M104.76125,13.48193a1.828,1.828,0,0,1-1.95117,1.30273A2.04531,2.04531,0,0,1,100.73,12.46045a2.07685,2.07685,0,0,1,2.07617-2.35254c1.25293,0,2.00879.856,2.00879,2.27V12.688h-3.17969v.0498a1.1902,1.1902,0,0,0,1.19922,1.29,1.07934,1.07934,0,0,0,1.07129-.5459Zm-3.126-1.45117h2.27441a1.08647,1.08647,0,0,0-1.1084-1.1665A1.15162,1.15162,0,0,0,101.63527,12.03076Z" />
        </g>
      </g>
    </g>
  </svg>
);

const PlaystoreIcon = () => (
  <svg
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_61_38)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.358244 0.309204C0.13116 0.553906 0 0.927812 0 1.41722V18.7754C0 19.2648 0.13116 19.6387 0.366075 19.8736L0.426761 19.9265L10.1522 10.201V9.98376L0.418931 0.256348L0.358244 0.309204Z"
        fill="url(#paint0_linear_61_38)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3883 13.4524L10.1445 10.2087V9.98157L13.3883 6.73779L13.4587 6.78086L17.2957 8.96165C18.3939 9.58026 18.3939 10.6022 17.2957 11.2286L13.4587 13.4094L13.3883 13.4524Z"
        fill="url(#paint1_linear_61_38)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4583 13.4095L10.1441 10.0952L0.35791 19.8814C0.716155 20.2651 1.31715 20.3081 1.98861 19.9342L13.4583 13.4095Z"
        fill="url(#paint2_linear_61_38)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4583 6.78108L1.98861 0.266121C1.31715 -0.117572 0.714197 -0.0647167 0.35791 0.318977L10.1441 10.0953L13.4583 6.78108Z"
        fill="url(#paint3_linear_61_38)"
      />
      <path
        opacity="0.2"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3883 13.3389L1.9969 19.8108C1.36068 20.1769 0.792972 20.1514 0.426897 19.8186L0.366211 19.8793L0.426897 19.9322C0.792972 20.263 1.36068 20.2904 1.9969 19.9243L13.4666 13.4094L13.3883 13.3389Z"
        fill="black"
      />
      <path
        opacity="0.12"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2956 11.1152L13.3784 13.339L13.4488 13.4095L17.2858 11.2287C17.8359 10.9155 18.106 10.5044 18.106 10.0952C18.0727 10.4711 17.7928 10.8274 17.2956 11.1152Z"
        fill="black"
      />
      <path
        opacity="0.25"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.98943 0.379665L17.296 9.07541C17.7933 9.35535 18.0732 9.72143 18.1163 10.0953C18.1163 9.68619 17.8461 9.27509 17.296 8.96187L1.98943 0.266122C0.891205 -0.362273 0.000488281 0.162369 0.000488281 1.4172V1.53074C0.000488281 0.273953 0.891205 -0.240901 1.98943 0.379665Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_61_38"
        x1="9.27986"
        y1="1.22994"
        x2="-6.29864"
        y2="5.38085"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00A0FF" />
        <stop offset="0.00657" stop-color="#00A1FF" />
        <stop offset="0.2601" stop-color="#00BEFF" />
        <stop offset="0.5122" stop-color="#00D2FF" />
        <stop offset="0.7604" stop-color="#00DFFF" />
        <stop offset="1" stop-color="#00E3FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_61_38"
        x1="18.7279"
        y1="10.0952"
        x2="-0.266787"
        y2="10.0952"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFE000" />
        <stop offset="0.4087" stop-color="#FFBD00" />
        <stop offset="0.7754" stop-color="#FFA500" />
        <stop offset="1" stop-color="#FF9C00" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_61_38"
        x1="11.6562"
        y1="11.8987"
        x2="-0.850202"
        y2="32.9479"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF3A44" />
        <stop offset="1" stop-color="#C31162" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_61_38"
        x1="-2.10634"
        y1="-5.46443"
        x2="3.47324"
        y2="3.93648"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#32A071" />
        <stop offset="0.0685" stop-color="#2DA771" />
        <stop offset="0.4762" stop-color="#15CF74" />
        <stop offset="0.8009" stop-color="#06E775" />
        <stop offset="1" stop-color="#00F076" />
      </linearGradient>
      <clipPath id="clip0_61_38">
        <rect width="19" height="21" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const links = [
  {
    text: "Discord",
    href: DISCORD_INVITE_URL,
    Icon: <DiscordIcon />,
  },
  { text: "Accounts", href: "/accounts" },
  { text: "Contact", href: "mailto:hi@listty.app" },
  { text: "Privacy", href: "/privacy" },
  { text: "Terms Of Service", href: "/terms-of-service" },
];

const IndexPage = () => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (isMobile()) {
        const url = isIOS() ? APP_STORE_URL : GOOGLE_PLAY_URL;
        window.location.replace(url);
      }
    }
  }, []);
  return (
    <Page>
      <Seo title="Home" />
      <Main>
        <Container>
          <Heading>listty.</Heading>
          <Text
            style={{
              marginBottom: "42px",
            }}
          >
            An app where you can find lots of new music you’ll love and cherish.
          </Text>

          <Row
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PlatformIconWrapper href={APP_STORE_URL}>
              <AppStoreIcon />
            </PlatformIconWrapper>
            <PlatformIconWrapper href="https://play.google.com/store/apps/details?id=com.listtyandroid&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                style={{
                  width: "140px",
                  height: "40px",
                  objectFit: "cover",
                }}
              />
            </PlatformIconWrapper>
          </Row>

          <Footer>
            <nav>
              <List>
                {links.map((link, i) => (
                  <>
                    <ListItem key={i}>
                      {link.Icon ? (
                        <Row>
                          {link.Icon}
                          <Link target="_blank" href={link.href}>
                            {link.text}
                          </Link>
                        </Row>
                      ) : (
                        <Link target="_blank" href={link.href}>
                          {link.text}
                        </Link>
                      )}
                    </ListItem>
                  </>
                ))}
              </List>
            </nav>
          </Footer>
        </Container>
      </Main>
    </Page>
  );
};

export default IndexPage;
