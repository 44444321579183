export const isMobile = () => {
  const regex =
    /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regex.test(navigator.userAgent);
};

export const isIOS = () => {
  let platform = navigator?.userAgent || navigator?.platform || "unknown";

  return /iPhone|iPod|iPad/.test(platform);
};
